$color-black: #000;
$color-white: #fff;
// $turquoise: #3adcb0;
$color-purple: #8b00f9;
$color_purple_light: #a839ff;
$color-green: #2ba600;
$color-green_dark: #71faa233;
$color-red: #d0231c;
$color-red_light: #d45137;
$color-red_dark: #730313;
$color-blue_dark: #3d2a8c;
$color-blue_light: #3c78a7;
$color-gray_light: #dbdbdb;
$color-gray: #575656;
$color-gray_dark: #282828;
$color-yellow: #f5cf35;

$color-gren_gradient: #349856;
$color-blue_gradient: #21635f;

$color-yellow1: #d7bd69;
$color-yellow2: #ffeeba;
$color-yellow3: #654c01;

$color-background: #f4f4f4;

$background-color-warning: $color-yellow1;
$border-color-warning: $color-yellow2;
$color-warning: $color-yellow3;

$background-gradient: linear-gradient(
  180deg,
  $color_gren_gradient 0%,
  $color_blue_gradient 100%
);

$height-header-footer: 58px;
$max-width: 1500px;

$box-shadow-color: rgba(216, 216, 216, 0.25);
$box-shadow: 0px 4px 10px $box-shadow-color;
