.LoginPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8rem;

  .Card {
    padding-right: 6rem;
    padding-left: 6rem;

    .input-password {
      position: relative;

      .icon {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.375rem 0.75rem;
        cursor: pointer;
      }
    }
  }
}
